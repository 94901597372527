
export default {
  name: 'MoleculeImageThumbSlider',
  props: {
    thumbnails: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    typeOf: {
      required: false,
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      thumbImages: [],
      activeThumb: 0
    }
  },
  watch: {
    thumbnails: {
      deep: true,
      handler: function (value, old) {
        this.thumbImages = [...value]
      }
    }
  },
  mounted() {
    this.thumbImages = this.thumbnails
  },
  methods: {
    changeActiveThumb(index) {
      if (isNaN(index)) index = 0
      this.activeThumb = index

      this.pauseVideoOnThumbChange()
    },
    handleThumbChange(index) {
      this.changeActiveThumb(index)
      this.$emit('thumb-change', index)
    },
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters.cdnBase}/${url}`
    },
    pauseVideoOnThumbChange() {
      if (this.typeOf === 'video') {
        const currentSlider = $('.owl-item.active') // it is easy to use jquery in this context instead of refs.
        if (!currentSlider) return
        let activePlayer = currentSlider.find('iframe').get(0)
        if (activePlayer) {
          // activePlayer will be the iframe of youtube player
          activePlayer.contentWindow.postMessage(
            JSON.stringify({
              event: 'command',
              func: 'pauseVideo'
            }),
            '*'
          ) // we pause the video when user clicks on another video thumb card
        } else {
          activePlayer = currentSlider.find('video') // activePlayer will be the html video player
          if (activePlayer) {
            activePlayer.trigger('pause') // we trigger a pause action on the video when use clicks on another video thumb card
          }
        }
      }
    }
  }
}

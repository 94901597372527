
export default {
  name: 'MoleculeImageSlider',
  props: {
    sliderData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    typeOf: {
      required: true,
      type: String,
      default: function () {
        return 'image'
      }
    }
  },
  data() {
    return {
      instance: null
    }
  }
}


import { isMobile, isTablet } from '~/helpers/mobile/DeviceType'

export default {
  name: 'MoleculeSidebarAccordionResults',
  data() {
    return {
      isSpaceListExpanded: false,
      listData: [],
      filters: {}
    }
  },
  props: {
    pinsInfo: [],
    sidebarTitle: {
      required: false,
      type: String,
      default: ''
    }
  },
  computed: {
    buildingStore() {
      return this.$store.state.building
    },
    projectData() {
      return this.$store.state.project.project
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    cdnBase() {
      return this.$store.getters.cdnBase
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    }
  },
  watch: {
    requestedSpaces: {
      deep: true,
      handler: function () {
        this.removeEventListeners()
        this.addEventListeners()
      }
    },
    '$store.state.project.project.buildings': function () {
      this.removeEventListeners()
      this.addEventListeners()
    },
    '$store.state.project.activeProject': function () {
      this.removeEventListeners()
      this.addEventListeners()
    },
    '$store.state.project.filters': {
      deep: true,
      handler: function (filters) {
        this.filters = filters
        this.removeEventListeners()
        this.addEventListeners()
      }
    }
  },
  beforeUpdate() {
    this.removeEventListeners()
  },
  updated() {
    this.addEventListeners()
  },
  mounted() {
    this.removeEventListeners()
    this.addEventListeners()
  },
  methods: {
    removeEventListeners() {
      const parentFloorNodes = document.querySelectorAll('.parent-floor')
      parentFloorNodes.forEach((node) => {
        node.removeEventListener('click', this.itemClickEventListener.bind(null, node))
      })
    },
    addEventListeners() {
      const parentFloorNodes = document.querySelectorAll('.parent-floor')
      parentFloorNodes.forEach((node) => {
        node.addEventListener('click', this.itemClickEventListener.bind(null, node))
      })
    },
    resetView() {
      if (isMobile() || isTablet()) {
        this.$store.dispatch('building/clearSpaceData')
      }
    },
    activateSpecificPin(pinObject) {
      const getSiblings = function (elem) {
        let descendants = elem.parentNode.children
        return Array.prototype.filter.call(descendants, function (sibling) {
          return sibling !== elem
        })
      }
      const target = event.target
      getSiblings(target).forEach((sibling) => {
        sibling.classList.remove('activated')
      })
      target.classList.toggle('activated')
      const closestFloorRow = event.target.closest('.row-floor')
      getSiblings(closestFloorRow).forEach((sibling) => {
        sibling.classList.remove('activation')
      })
      closestFloorRow.classList.add('activation')

      const buildingList = document.querySelector('.building-list')
      buildingList.querySelectorAll('.row-floor').forEach((childRow) => {
        if (childRow !== closestFloorRow) {
          childRow.classList.remove('activation')
        }
      })
    },
    showSpecificPins(pinSection) {
      // document.querySelectorAll('.parent-floor').forEach(item=>{
      //   item.classList.remove('active')
      // })
    },

    goToPin(pinObject) {
      document.querySelectorAll('.parent-floor').forEach((item) => {
        item.classList.remove('active')
      })
    },

    itemClickEventListener(node, e) {
      // if (!e.target.classList.contains('icon')) {
      const floorInfoList = document.querySelectorAll('.floor-info-parent')
      floorInfoList.forEach((floorItem) => {
        floorItem.querySelectorAll('.row-floor').forEach((rowFloor) => {
          rowFloor.classList.remove('activation')
        })
        floorItem.querySelectorAll('.trigger-click-view-space').forEach((elemView) => {
          elemView.classList.remove('activated')
        })

        if (floorItem.querySelector('.parent-floor') !== node) {
          floorItem.querySelector('.parent-floor').classList.remove('active')
          floorItem.querySelector('.show-all-spaces').style.display = 'none'
        }
      })

      node.classList.toggle('active')
      const siblingSpacesContainer = node.parentNode.querySelector('.show-all-spaces')
      if (siblingSpacesContainer) {
        siblingSpacesContainer.style.display =
          siblingSpacesContainer.style.display === 'block' ? 'none' : 'block'
      }
      // }
    }
  }
}


export default {
  name: 'MoleculeSliderSlide',
  props: {
    visibleSlide: {
      required: true,
      default: 0
    },
    index: {
      required: true,
      default: 0
    },
    imageSrc: {
      required: false,
      default: ''
    },
    direction: {
      required: false,
      default: 'left'
    },
    titles: {
      required: false,
      type: Array,
      default: function () {
        return []
      }
    },
    descriptions: {
      required: false,
      type: Array,
      default: function () {
        return []
      }
    },
    background: {
      required: false,
      type: String,
      default: function () {
        return '#5bb4e5'
      }
    }
  },
  mounted() {
    if (!this.imageSrc) {
      this.$el.getElementsByTagName('div')[0].style.backgroundColor = this.background
    }
  }
}
